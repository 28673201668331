import React, { FC } from 'react';
import classNames from 'classnames';
import styles from '../forms.module.scss';
import { DropdownItem } from '../types';

type Props = {
  value: DropdownItem;
  allowedValues?: Array<any>;
  placeholder?: string;
  shift?: boolean;
};

const CurrentValue: FC<Props> = ({ value, placeholder, shift, allowedValues }) => (
  <>
    {(!value || !allowedValues.includes(value.value)) && (
      <span className={styles.dropdownValue__placeholder}>{placeholder || '---'}</span>
    )}

    {value && allowedValues.includes(value.value) && (
      <span
        className={classNames(styles.dropdownValue__value, {
          [styles['dropdownValue__value--shift']]: shift
        })}
      >
        {value.valueLabel || value.label || value.value}
      </span>
    )}
  </>
);

CurrentValue.defaultProps = {
  placeholder: undefined,
  shift: false,
  allowedValues: []
};

export default CurrentValue;

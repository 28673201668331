import React, { FC } from 'react';
import classNames from 'classnames';
import { ComponentSize, DropdownItem } from '../types';

import styles from '../forms.module.scss';

type Props = {
  item: DropdownItem;
  highlight?: boolean;
  current?: boolean;
  size?: ComponentSize;
  onMouseEnter?(): void;
  onClick?: (item: DropdownItem) => void;
};

const Option: FC<Props> = ({ item, highlight, current, size, onClick, onMouseEnter }) => {
  if (!item) return null;

  return (
    <li>
      <button
        type="button"
        className={classNames(
          styles.dropdownOptions__item,
          size !== 'default' ? styles[`dropdownOptions__item--${size}`] : undefined,
          {
            [styles['dropdownOptions__item--current']]: current,
            [styles['dropdownOptions__item--highlight']]: highlight
          }
        )}
        onClick={() => onClick(item)}
        onMouseEnter={onMouseEnter}
      >
        {item.label || item.value}
      </button>
    </li>
  );
};

Option.defaultProps = {
  highlight: false,
  current: false,
  onClick: () => null,
  onMouseEnter: () => null,
  size: 'default'
};

export default Option;
